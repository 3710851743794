import { getConfigValue } from './config';

export function imageUrl(src: string) {
	if (src.startsWith('/')) {
		const hostname = getConfigValue('MAP_HOST');
		const url = new URL(src, `https://${hostname}`);
		return url.toString();
	}

	return src;
}
