import { Show, createMemo, createSignal } from 'solid-js';
import { ActivityIndicator, Link } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { useIsRouting } from '@solidjs/router';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { createNumberFormatter, holesFormatter } from '../modules/number-formatting';
import { gql } from '../graphql';
import { dayTimeToDate, formatDateTimeAttribute } from '../modules/date-formatting';
import type { DayTimeFragment, TeeTimeFragment } from '../graphql';

type Props = TeeTimeFragment & {
	dayTime: DayTimeFragment;
	slug?: string;
	showCourse?: boolean;
};

export function TeeTime(props: Props) {
	const trackEvent = useTrackEvent();
	const [clicked, setClicked] = createSignal(false);
	const isRouting = useIsRouting();

	const numberFormatter = createNumberFormatter();

	const dayTime = createMemo(() => {
		const date = dayTimeToDate(props.dayTime);
		const dayPeriod = dayjs(date).format('A');
		const time = dayjs(date).format('h:mm');
		return { dayPeriod, time };
	});

	const feeString = createMemo(() => {
		const min = props.minPrice;
		const max = props.maxPrice;
		const currency = min.code ?? 'USD';
		const dollarFormatter = new Intl.NumberFormat('en', {
			style: 'currency',
			currency,
			currencyDisplay: 'narrowSymbol',
			trailingZeroDisplay: 'stripIfInteger',
		});

		if (min.value !== max.value) {
			return dollarFormatter.formatRange(min.value, max.value);
		}
		return dollarFormatter.format(min.value);
	});

	return (
		<div class="group relative p-4 transition-colors duration-200 hover:bg-neutral-100 md:p-6">
			<div class="mb-2 flex flex-row justify-between gap-8">
				<Link
					preload={false}
					class="after:absolute after:inset-0 after:z-0"
					href={`/course/${props.course.facility?.slug ?? ''}/reserve-tee-time/${props.id}`}
					rel="nofollow"
					onClick={() => {
						setClicked(true);
						trackEvent('didSelectTeeTime', {
							courseId: props.courseId,
							...props.dayTime.day,
							...props.dayTime.time,
						});
					}}
				>
					<span class="sr-only">Reserve</span>
					<time datetime={formatDateTimeAttribute(dayTimeToDate(props.dayTime))}>
						<span class="text-2xl font-semibold">{dayTime().time}</span>
						<span class="text-sm">{dayTime().dayPeriod}</span>
					</time>
					<span class="sr-only">
						For{' '}
						{props.minPlayers !== props.maxPlayers
							? numberFormatter().formatRange(props.minPlayers, props.maxPlayers)
							: numberFormatter().format(props.minPlayers)}{' '}
						golfers on {holesFormatter(numberFormatter(), props.holesOption)} holes.
					</span>
				</Link>

				<div class="flex flex-row gap-4 font-semibold">
					<Show when={clicked() && isRouting()}>
						<ActivityIndicator class="text-neutral-600" />
					</Show>
					{feeString()}
				</div>
			</div>

			<div class="flex flex-row flex-wrap justify-between gap-x-8 gap-y-1">
				<Show when={props.showCourse}>
					<div class="grow">{props.course.name}</div>
				</Show>
				<ul class="flex shrink flex-row gap-4 text-neutral-800">
					<li class="inline-flex items-center gap-x-1">
						<Icon name="users" />
						{props.minPlayers !== props.maxPlayers
							? numberFormatter().formatRange(props.minPlayers, props.maxPlayers)
							: numberFormatter().format(props.minPlayers)}{' '}
						<span class="sr-only">golfer(s)</span>
					</li>
					<li class="inline-flex items-center gap-x-1">
						<Icon name="flag" />
						<span>{holesFormatter(numberFormatter(), props.holesOption)} holes</span>
					</li>
					{props.cartIncluded ? (
						<li class="inline-flex items-center gap-x-1">
							<Icon name="golf-cart" title="Cart" /> Included
						</li>
					) : null}
				</ul>
			</div>
		</div>
	);
}

gql(`fragment TeeTime on CourseTeeTime {
	id
	courseId
	course {
		name
		facility {
			slug
		}
		requiresCCAtBooking
	}
	dayTime {
		...DayTime
	}
	minPrice {
		 ...Currency
	}
	maxPrice {
		...Currency
	}
	minPlayers
	maxPlayers
	holesOption
	cartIncluded
	practiceBallsIncluded
	rates {
		id
		name
		price {
			...Currency
		}
	}
}`);
