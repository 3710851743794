import { createSignal, createUniqueId, For, Show } from 'solid-js';
import {
	Label,
	RadioGroup,
	RadioBar,
	RadioBarButton,
	Field,
	Select,
	Option,
	Calendar,
	DialogContent,
	Dialog,
	DialogActions,
	DialogAction,
	TextLink,
} from '@troon/ui';
import dayjs from '@troon/dayjs';
import { timeframes } from '../../../../../../modules/date-formatting';
import type { Facility } from '../../../../../../graphql';
import type { Dayjs } from '@troon/dayjs';

export type Store = {
	includeCart: boolean;
	players: number | undefined;
	courseIds: Array<string>;
	startAt: number;
	endAt: number;
	date: string;
};

type FilterProps = {
	facility: Facility;
	onSelectPlayers: (players: number | undefined) => void;
	onSelectCourse: (courseId: string) => void;
	onSelectTime: (frame: [number, number]) => void;
	onSwitchIncludesCart: (include: boolean) => void;
	onSelectDate: (dateString: string) => void;
	minDate: Dayjs;
	maxDate?: Dayjs;
} & Store;

export function TeeTimeFilters(props: FilterProps) {
	const [open, setOpen] = createSignal(false);
	const dialogId = createUniqueId();

	return (
		<>
			<Calendar
				label="Tee times {date}"
				dayLabel="Show tee times for {date}"
				minDate={props.minDate}
				maxDate={props.maxDate}
				selectedDate={dayjs.tz(props.date, props.facility.timezone)}
				onSelect={(date) => {
					const day = dayjs(date);
					props.onSelectDate(day.format('YYYY-MM-DD'));
				}}
				onSelectInvalid={() => {
					setOpen(true);
				}}
				// renderOutOfRange={(props) => <OutOfRange {...props} onClick={() => setOpen(true)} />}
				timezone={props.facility.timezone}
			/>

			<Dialog key="tee-time-date-out-of-range" open={open()} onOpenChange={setOpen} id={dialogId}>
				<DialogContent header="Outside booking window" headerLevel="h3">
					<div class="flex flex-col gap-8">
						<p>
							This day is outside of the booking window for {props.facility.name}. For more information, contact the
							course at{' '}
							<TextLink href={`tel:${props.facility.metadata?.phone}`}>{props.facility.metadata?.phone}</TextLink>.
						</p>
						<DialogActions>
							<DialogAction onClick={() => setOpen(false)}>Okay</DialogAction>
						</DialogActions>
					</div>
				</DialogContent>
			</Dialog>

			<Field name="time">
				<Label>Time</Label>
				<Select
					onChange={(e) => {
						props.onSelectTime(timeframes[e.currentTarget.value]!);
					}}
				>
					<For each={Object.entries(timeframes)}>
						{([label, [start, end]]) => (
							<Option selected={start === props.startAt && end === props.endAt}>{label}</Option>
						)}
					</For>
				</Select>
			</Field>

			<RadioGroup name="radios" onSelect={(value) => props.onSelectPlayers(parseInt(value, 10))}>
				<Label>Players</Label>
				<RadioBar>
					<RadioBarButton checked={!props.players}>
						<Label>Any</Label>
					</RadioBarButton>
					<RadioBarButton value="1" checked={props.players === 1}>
						<Label>1</Label>
					</RadioBarButton>
					<RadioBarButton value="2" checked={props.players === 2}>
						<Label>2</Label>
					</RadioBarButton>
					<RadioBarButton value="3" checked={props.players === 3}>
						<Label>3</Label>
					</RadioBarButton>
					<RadioBarButton value="4" checked={props.players === 4}>
						<Label>4</Label>
					</RadioBarButton>
				</RadioBar>
			</RadioGroup>

			<Show when={(props.facility.courses.length ?? 0) > 1}>
				<Field name="course">
					<Label>Course</Label>
					<Select
						onChange={(e) => {
							props.onSelectCourse(e.currentTarget.value);
						}}
					>
						<Option value="__all__">All courses</Option>
						<optgroup label="Courses">
							<For each={props.facility.courses}>
								{(course) => (
									<Option value={course.id} selected={props.courseIds.length === 1 && props.courseIds[0] === course.id}>
										{course.name}
									</Option>
								)}
							</For>
						</optgroup>
					</Select>
				</Field>
			</Show>
		</>
	);
}
