import { Button } from '@troon/ui';
import { Show } from 'solid-js';
import { Icon } from '@troon/icons';
import type { ParentProps } from 'solid-js';

export function WelcomeBanner(props: ParentProps<{ onDismiss?: () => void }>) {
	return (
		<div class="flex w-full flex-row items-center justify-stretch bg-brand-100 px-4 py-2 text-brand md:px-8">
			<div class="flex shrink grow flex-row flex-wrap items-center justify-center">{props.children}</div>
			<Show when={props.onDismiss}>
				<Button class="shrink grow-0" appearance="transparent-current" onClick={props.onDismiss}>
					<Icon name="close-md" />
					<span class="sr-only">Close</span>
				</Button>
			</Show>
		</div>
	);
}
